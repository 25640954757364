import { request } from '@/utils/request'

/**
 *
 *存量报表
 */

// 角色列表
export function stockList () {
  return request({
    url: '/api/manage/stock/query',
    method: 'get'
  })
}

// 下载列表数据
export function downStock () {
  return request({
    url: '/api/manage/stock/exportExcel',
    method: 'get',
    responseType: 'blob', // 服务器返回的数据类型
    timeout: 500 * 1000
  })
}
