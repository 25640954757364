<template>
  <div class='stockreport'>

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">存量报表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="20">

      <el-col :span="6">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">当年存量业务预计收入</div>
            <div class="totalnumber">
              <span class="numbers">{{currYearEstimatedIncome}}</span>
              <span class="yuan">元</span>
            </div>
          </div>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">存量资产总数</div>
            <div class="totalnumber">
              <span class="numbers">{{assetsCount}}</span>
              <span class="yuan">辆</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover">
          <div class="totalBox">
            <div class="title">平均车价</div>
            <div class="totalnumber">
              <span class="numbers">{{carPriceAvg}}</span>
              <span class="yuan">元</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="box-card">
      <div class="downreport">
        <el-button type="warning" round size="mini" icon="el-icon-download" @click="DownExcel()">导出</el-button>
      </div>
      <div class="table">
        <el-table :data="stockListData" style="width: 100%" border stripe :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,backgroundColor:'#eff3f8'}">
          <el-table-column type="index" label="序" width="50">
            <!-- <template slot-scope="scope">
              <span>{{(pagenum - 1) * size + scope.$index + 1}}</span>
            </template> -->
          </el-table-column>
          <el-table-column prop="companyName" label="公司" width="180">
          </el-table-column>
          <el-table-column prop="currYearEstimatedIncome" label="当年存量预计收入（元）">
          </el-table-column>
          <el-table-column prop="assetsCount" label="资产数">
          </el-table-column>
          <el-table-column prop="carPriceAvg" label="平均车价（元）">
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { stockList, downStock } from '@/api/stockreport.js'
export default {
  name: '',
  data () {
    return {
      stockListData: [],
      currYearEstimatedIncome: '',
      assetsCount: '',
      carPriceAvg: ''
    }
  },
  created () {
    this.getStocList()
  },
  methods: {
    getStocList () {
      stockList().then((res) => {
        console.log(res)
        this.currYearEstimatedIncome = Number(
          res.data.currYearEstimatedIncome
        ).toLocaleString()
        this.assetsCount = Number(res.data.assetsCount).toLocaleString()
        this.carPriceAvg = Number(res.data.carPriceAvg).toLocaleString()
        const stockListData = res.data.stocks
        // 二次梳理数据格式
        stockListData.forEach((ele) => {
          ele.currYearEstimatedIncome = Number(
            ele.currYearEstimatedIncome
          ).toLocaleString()
          ele.carPriceAvg = Number(ele.carPriceAvg).toLocaleString()
        })
        this.stockListData = stockListData
        // console.log(stockListData);
      })
    },
    // 导出报表
    DownExcel () {
      var _this = this
      downStock()
        .then((res) => {
          console.log(res)
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel'
          })
          const newTime = _this.$moment().format('YYYYMMDDkkmmss')
          const fileName = 'report_' + newTime // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob)

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement('a')
          dom.style.display = 'none'
          dom.href = url
          dom.setAttribute('download', fileName)
          document.body.appendChild(dom)
          dom.click()

          this.$message.success('正在下载...')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang='less' scoped>
.stockreport {
  padding: 60px 20px 20px 20px;
  .totalBox {
    padding: 0 5px;
    .numbers {
      font-size: 30px;
      line-height: 60px;
      font-weight: 900;
    }
    .yuan {
      font-weight: 900;
      margin: 0 4px;
    }
  }
  .box-card {
    margin-top: 10px;
  }
  .downreport {
    padding-bottom: 16px;
  }
  .table {
    width: 80%;
    /deep/.el-table th > .cell {
      text-align: center;
    }

    /deep/.el-table .cell {
      text-align: center;
    }
  }
}
</style>
